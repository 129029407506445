import React, { Component } from "react";
import "../scss/Batches.scss";
import ak_pic from "../images/ak_pic.png";
import amansirleft from "../images/productImg.jpg";
import amansirright from "../images/amansirright.png";
// import toofan from "../images/toofan.png";
import Books from "./Books";
import axios from "axios";
import { Link } from "react-router-dom";
import Constants from "./common/Constants";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default class Batches extends Component {
  state = {
    courses: [],
  };
  componentDidMount() {
    axios
      .get(Constants.getUrls.specialCourse + "&is_featured=1")
      .then((respCourse) => {
        console.log("Courses Data" , respCourse);
        if (respCourse.data.data.length > 0) {
          let businessMathCourse = respCourse.data.data.filter(
            (courseTitle) => courseTitle.is_featured === 1
          );
          console.log(businessMathCourse);
          this.setState({
            courses: businessMathCourse,
          });
        }
      });
  }

  render() {
    const height = window.innerWidth < 768 ? "400px" : "450px";
    return (
      <div className="">
        <div className="row ">
          <div className="batches-container">
            <Swiper
              style={{ padding: "0rem 3rem 0rem 0rem", height: height }}
              slidesPerView={3}
              spaceBetween={30}
              pagination={true}
              autoplay={{
                delay: 2500,
              }}
              modules={[Autoplay, Pagination]}
              breakpoints={{
                375: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                999: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                280: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
              }}
            >
              {this.state.courses.map((course) => (
                <SwiperSlide key={course.ID}>
                  <div className="batch-container">
                    {course.course_batches[0].batch.registration_end_date ===
                      null ||
                    course.course_batches[0].batch.registration_end_date ===
                      "0000-00-00" ? (
                      <strong></strong>
                    ) : (
                      <strong>
                        Registration Ends on{" "}
                        {moment(
                          course.course_batches[0].batch.registration_end_date
                        ).format("MMM Do YYYY")}
                      </strong>
                    )}
                    <div className="batch-card">
                      <div className="batch-thumb">
                        <img
                          src={`https://admin.akclasses.in/${course.IMAGE_PATH}`}
                          alt={course.TITLE}
                        />
                      </div>
                      <div className="batch-info">
                        <h5>{course.TITLE}</h5>

                        <div className="langauge-container">
                          <h6>
                            Teaching Language <span>{course.language}</span>
                          </h6>

                          <div className="lang-date">
                            {course?.attempt?.title}
                          </div>
                        </div>
                      </div>
                      <div className="batch-divider-line"></div>
                      <div className="batch-details">
                        <h6>
                          <i
                            class="fa-regular fa-clock"
                            style={{ marginRight: "4px" }}
                          ></i>
                          {course.DURATION}
                        </h6>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={
                            "/course_details/" +
                            course?.STREAM_ID +
                            "/" +
                            course?.attempt +
                            "/" +
                            course?.course_batches[0]?.subject_id +
                            "/" +
                            course?.course_batches[0]?.batch?.id +
                            "/" +
                            course?.course_batches[0].course_id +
                            "/" +
                            course?.course_batches[0]?.batch_wings_id
                          }
                        >
                          <button>Get Details</button>
                        </Link>
                        <h6>{course.variant[0].price}/-</h6>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            {/* <div className="batch-container">
              <strong>Registration Ends on 2023-01-31</strong>
              <div className="batch-card">
                <div className="batch-thumb">
                  <img
                    src={
                      "https://admin.akclasses.in/storage/Courses/images/694928828.jpg"
                    }
                    alt="title"
                  />
                </div>
                <div className="batch-info">
                  <h5>BMSLR - Monitoring Test</h5>
                  <h6>
                    Teaching Language <span>Hindi</span>
                  </h6>
                </div>
                <div className="batch-details">
                  <h6>150 Hrs.</h6>
                  <button>Get Details</button>
                  <h6>6500/</h6>
                </div>
              </div>
            </div>
            <div className="batch-container">
              <strong>Registration Ends on 2023-01-31</strong>
              <div className="batch-card">
                <div className="batch-thumb">
                  <img
                    src={
                      "https://admin.akclasses.in/storage/Courses/images/694928828.jpg"
                    }
                    alt="title"
                  />
                </div>
                <div className="batch-info">
                  <h5>BMSLR - Monitoring Test</h5>
                  <h6>
                    Teaching Language <span>Hindi</span>
                  </h6>
                </div>
                <div className="batch-details">
                  <h6>150 Hrs.</h6>
                  <button>Get Details</button>
                  <h6>6500/</h6>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div
            className="col-sm-6"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <h4 className={"wingName1536"}>
              <span className="param">Toofan Batch: </span> Teaching Language
              Hindi
            </h4>
            <div className="imageAreaMain">
              <div
                className="imgWrapper row"
                style={{
                  backgroundImage: `url(https://admin.akclasses.in//storage/Courses/images/870206531.jpg)`,
                }}
              >
                <div className="col-sm-6 col-xs-6 paddingClass"></div>
              </div>
              <div className="daysArea">
                <div style={{ textAlign: "center" }}>
                  <h3 className="ttl">
                    Business Mathematics Statistics & LR - Toofan Batch
                  </h3>
                  <h4 className="registerEnd">
                    Registration Ends on 15th November 2022
                  </h4>
                </div>
                <div className="daysDiv">
                  <h4 className="days">
                    <i
                      class="fa-solid fa-check hexCode"
                      style={{ color: "#3f92ad" }}
                    ></i>
                    <span className="totalDays"> 100 Days</span>{" "}
                    <span>Target Strategy</span>
                  </h4>

                  <h4 className="days">
                    <i
                      class="fa-solid fa-check hexCode"
                      style={{ color: "#3f92ad" }}
                    ></i>
                    <span className="totalDays"> 15 Mock Test</span>{" "}
                    <span>Papers with Evaluation</span>
                  </h4>

                  <h4 className="days">
                    <i
                      class="fa-solid fa-check hexCode"
                      style={{ color: "#3f92ad" }}
                    ></i>
                    <span className="totalDays"> 20 Live</span>{" "}
                    <span>Revision Sessions</span>
                  </h4>

                  <h4 className="days">
                    <i
                      class="fa-solid fa-check hexCode"
                      style={{ color: "#3f92ad" }}
                    ></i>
                    <span className="totalDays"> 12 Live</span>{" "}
                    <span>Quiz Sessions</span>
                  </h4>

                  <h4 className="days">
                    <i
                      class="fa-solid fa-check hexCode"
                      style={{ color: "#3f92ad" }}
                    ></i>{" "}
                    Doubt Sessions
                    <span className="totalDays"> as per 100 Days</span>
                  </h4>
                </div>
              </div>
              <div className="enrollArea">
                <div className=" row">
                  <div
                    className="col-md-4 col-xs-4"
                    style={{ textAlign: "left" }}
                  >
                    <h3
                      className="fontSize hrMargin"
                      style={{ color: "#3f92ad" }}
                    >
                      <i
                        class="fa-regular fa-clock"
                        style={{ marginRight: "4px" }}
                      ></i>
                      20 Days
                    </h3>
                  </div>
                  <div
                    className="col-md-4 col-xs-4"
                    style={{ textAlign: "center" }}
                  >
                    <a
                      href="https://akclasses.in/course_details/1/4/1/38/226/45 "
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        className="enrollBtn"
                        style={{ backgroundColor: "#3f92ad" }}
                      >
                        Enroll Now
                      </button>
                    </a>
                  </div>
                  <div className="col-md-4 col-xs-4">
                    <h3
                      className="priceMarg"
                      style={{
                        color: "#3f92ad",
                        textAlign: "right",
                        marginRight: "10px",
                      }}
                    >
                      499/-
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-6"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <h4 className="wingName2">
              <span className="param2">Lakshya Batch: </span> Teaching Language
              English
            </h4>
            <div className="imageAreaMain">
              <div className="imgWrapper row">
                <div className="col-sm-6 col-xs-6"></div>
              </div>
              <div className="daysArea">
                <div style={{ textAlign: "center" }}>
                  <h3 className="ttl">
                    Business Mathematics Statistics & LR - Nov 22
                  </h3>
                  <h4 className="registerEnd">
                    Registration Ends on 31st August 2022
                  </h4>
                </div>
                <div className="daysDiv">
                  <h4 className="days">
                    <i class="fa-solid fa-check hexCode"></i>

                    <span className="totalDays"> 75 Days</span>
                    <span>Target Strategy</span>
                  </h4>

                  <h4 className="days">
                    <i class="fa-solid fa-check hexCode"></i>
                    <span className="totalDays"> 22 Mock Test</span>{" "}
                    <span>Papers with Evaluation</span>
                  </h4>

                  <h4 className="days">
                    <i class="fa-solid fa-check hexCode"></i>
                    <span className="totalDays"> 15 Live</span>{" "}
                    <span>Revision Sessions</span>
                  </h4>

                  <h4 className="days">
                    <i class="fa-solid fa-check hexCode"></i>
                    <span className="totalDays"> 10 Live</span>{" "}
                    <span>Quiz Sessions</span>
                  </h4>

                  <h4 className="days">
                    <i class="fa-solid fa-check hexCode"></i> Doubt Sessions
                    <span className="totalDays"> as per 75 Days</span>
                  </h4>
                </div>
              </div>

              <div className="enrollArea">
                <div className=" row">
                  <div
                    className="col-md-4 col-xs-4"
                    style={{ textAlign: "left", color: "green" }}
                  >
                    <h3 className="fontSize hrMargin">
                      <i
                        class="fa-regular fa-clock"
                        style={{ marginRight: "4px" }}
                      ></i>
                      150Hrs.
                    </h3>
                  </div>
                  <div
                    className="col-md-4 col-xs-4"
                    style={{ textAlign: "center" }}
                  >
                    <a
                      href="https://akclasses.in/course_details/1/4/1/35/213/45 "
                      style={{ textDecoration: "none" }}
                    >
                      <button className="enrollBtn">Enroll Now</button>
                    </a>
                  </div>
                  <div className="col-md-4 col-xs-4">
                    <h3
                      className="priceMarg"
                      style={{
                        color: "green",
                        textAlign: "right",
                        marginRight: "10px",
                      }}
                    >
                      6500/-
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
