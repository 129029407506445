import React, { Component } from "react";
import "../scss/Testimonials.scss";
import JoinNow from "./JoinNow";
import YouTube from "react-youtube";
import YouTubeTestimonials from "./YouTubeTestimonials";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import axios from "axios";
import Constants from "./common/Constants";

export default class Testimonials extends Component {
  state = {
    videoLink: "",
    batch: "",
    studentName: "",
    marks: "",
    whatStudentsSay: [],
  }
  
  componentDidMount() { 
    axios.get(Constants.getUrls.getWhatStudentSay)
    .then(resp => {
      console.log("Testimonial Data",resp);
      this.setState({
        whatStudentsSay: resp.data.data.data
      })
    })
   }
  render() {
    const opts = {
      height: "248",
      width: "95%",
      playerVars: {
        autoplay: 0,
      },
    };
    return (
      <div>
        <div className="testimonials">
          <div>
            <h2 className="whatSay">
              What Students&nbsp;
              <span>
                Say About {window.innerWidth < 768 && <br />}
                <span style={{ color: "#0668E2" }}> Aman Khedia Sir</span>
              </span>
              {/* <br /> */}
              {/* <span className="whatFont" style={{ color: "#077c07" }}>
                Business Mathematics Statistics & LR
              </span> */}
            </h2>
          </div>

          <div className="testimonials-container">
            <Swiper
              // style={{ padding: "3rem 0rem" }}
              slidesPerView={3}
              spaceBetween={30}
              pagination={true}
              autoplay={{
                delay: 2500,
              }}
              modules={[Autoplay, Pagination]}
              breakpoints={{
                375: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                999: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                280: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
              }}
            >
              {
                this.state.whatStudentsSay.map((whatSay, index) => {
                  // Extracting the 'v' value from the video link
                  const videoId = whatSay.video_link.split('v=')[1];
                  const embedId = videoId ? videoId : '';

                  return (
                    <SwiperSlide key={index}>
                      <div className="testimonial-card">
                        <div className="testimonial-video">
                          <YouTubeTestimonials
                            embedId={embedId}
                            width="100%"
                            height="240"
                          />
                        </div>
                        <div className="testimonial-review-container">
                          <div className="review-info">
                            <h4>{whatSay ? whatSay.student_name : ""}</h4>
                            <span>Batch: {whatSay ? whatSay.batch ? whatSay.batch.batch_name : "" : ""}</span>
                          </div>
                          <div className="review-marks">
                            <h5>Marks: {whatSay ? whatSay.marks : ""}</h5>
                          </div>
                        </div>
                        <div className="testimonial-divider"></div>
                      </div>
                    </SwiperSlide>
                  );
                })
              }


              {/* <SwiperSlide>
                <div className="testimonial-card">
                  <div className="testimonial-video">
                    <YouTubeTestimonials
                      embedId={"1c49F4Q5wi4"}
                      width="100%"
                      height="240"
                    />
                  </div>
                  <div className="testimonial-review-container">
                    <div className="review-info">
                      <h4>Piyush Singla</h4>
                      <span>Batch: Param Batch</span>
                    </div>

                    <div className="review-marks">
                      <h5>Marks 78</h5>
                    </div>
                  </div>

                  <div className="testimonial-divider"></div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="testimonial-card">
                  <div className="testimonial-video">
                    <YouTubeTestimonials
                      embedId={"lrWmfIHpfls"}
                      width="100%"
                      height="240"
                    />
                  </div>
                  <div className="testimonial-review-container">
                    <div className="review-info">
                      <h4>Kunal</h4>
                      <span>Batch: OLD Batch</span>
                    </div>

                    <div className="review-marks">
                      <h5>Marks 92</h5>
                    </div>
                  </div>

                  <div className="testimonial-divider"></div>
                </div>
              </SwiperSlide> */}
              
            </Swiper>
          </div>
        </div>
      </div>
    );
  }
}
